<template>
    <address class="col-start-2 col-end-3 row-start-1 md:row-start-2 md:col-start-1 lg:row-start-1 lg:col-start-3 lg:col-end-4 xl:col-start-3 text-xs sm:text-sm not-italic break-all flex justify-end sm:justify-start">
        <div class="text-cool-800 flex flex-wrap items-center gap-x-1">
            <UIcon name="i-heroicons-solid-map-pin" class="w-4 h-4 md:w-6 md:h-6 shrink-0" />
            <div class="font-bold flex-shrink">Our Address</div>
            <ULink to="#" class="lg:ml-7 text-blue-700 font-medium md:font-bold underline hover:no-underline">515 Hamilton St, Costa Mesa, CA 92627</ULink>
        </div>

    </address>
</template>
<script setup lang="ts">
</script>
